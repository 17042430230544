<template>
  <div>
    <TopNavVue/>
    
    <el-row class="cloudDesktop">
      <el-col :span="22" :offset="1">
        <!-- 页面标题 -->
        <h6 class="title">应用上云</h6>
      </el-col>

      <el-col :span="22" :offset="1" class="cloa">
        <!-- <p>磐石高价值应用上云服务平台是面向工程设计和工程仿真类应用场景专属打造的高价值服务平台，是“软件即服务”理念在高端设计应用领域的具体落地，该平台全面兼容当下主流基础计算资源，更加提供了与云平台全面适配解决方案，实现工程设计等专业应用许可与硬件资源的高效共享，有效保护了投资。</p>
        <p>磐石高价值应用上云服务平台实现将专业软件使用许可通过应用发布等组件以“许可服务”方式在云数据中心进行托管，并支持进行统一管理、集中控制和按需发布等功能，有效解决了专业设计类软件资源共享难题，提高了专业应用使用效率，最大限度拓展了专业软件应用场景，为用户提供了可与专业图形工作站相媲美的操作体验。</p> -->
        <p>磐石高价值应用上云服务平台是面向工程设计和工程仿真类应用场景专属打造的高价值服务平台，是“软件即服务”理念在高端设计应用领域的具体落地，该平台全面兼容当下主流基础计算资源，更加提供了与云平台全面适配解决方案，实现工程设计等专业应用许可与硬件资源的高效共享，有效保护了投资。</p>
        <p>磐石高价值应用上云服务平台实现将专业软件使用许可通过应用发布等组件以“许可服务”方式在云数据中心进行托管，并支持进行统一管理、集中控制和按需发布等功能，有效解决了专业设计类软件资源共享难题，提高了专业应用使用效率，最大限度拓展了专业软件应用场景，为用户提供了可与专业图形工作站相媲美的操作体验。</p>
        <img src="@/assets/mobile/moPanshProduct/c8.png"/>
      </el-col>

      <!-- <el-col :span="22" :offset="1">
        <h6 class="title">产品结构</h6>
        <div class="clob">
          <p>逻辑架构</p>
          <img src="@/assets/panshProduct/application/jiagou01.png"/>
        </div>
      </el-col> -->
      
      <el-col :span="22" :offset="1">
        <h6 class="title">产品功能</h6>
        <div class="clob">
          <div v-for="(item,key) in proFunction" :key="key" class="cloba">
            <img :src="item.imgSrc"/>
            <p>{{item.title}}</p>
            <p>{{item.desc}}</p>
          </div>
          <div class="clearfix"></div>
        </div>
      </el-col>

      <el-col :span="22" :offset="1">
        <h6 class="title">产品特色</h6>
        <div class="cloc">
          <img src="@/assets/mobile/moPanshProduct/c9.png" alt="">
          <div class="cloca">
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>资源统一管理，按需发布</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>闲置资源及时回收</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>专业设计软件许可以服务方式发布</p>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>媲美工作站的用户体验</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>完美的软件兼容性</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>广泛的外设支持</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>成熟的vGPU支持</p>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>任意时间、任意地点、使用任意企业应用</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>全面适配麒麟、统信等国产操作系统</p>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="cloc">
          <img src="@/assets/mobile/moPanshProduct/c10.png" alt="">
          <div class="cloca">
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>统一域名访问，资源共享</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>多区域协同办公</p>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>合理利用虚拟化资源，节省硬件投资</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>统一运维管理，节约人力成本</p>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="clocb">
              <div>
                <div class="clocba"></div>
                <p>统一管理平台，管理、交付、监控于一体</p>
                <div class="clearfix"></div>
              </div>
              <div>
                <div class="clocba"></div>
                <p>定制化云端管理功能；自动运维</p>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </el-col>

      <el-col :span="22" :offset="1" style="margin-top: 20px;">
        <h6 class="title">应用场景</h6>
        <div class="clob">
          <img src="@/assets/panshProduct/cloudDesktop/cj1.png" alt="">
        </div>
      </el-col>

      
    </el-row>

    <footerMobileVue/>


  </div>
</template>

<script>
import TopNavVue from '../../../components/topNav.vue'
import footerMobileVue from '../../../components/footerMobile.vue';
export default {
    name: "cloudApplicationMobile",
    components:{footerMobileVue,TopNavVue},
    data() {
        return {
          proFunction:[
            {title:'安全接入',desc:'磐石高价值应用上云服务平台安全网关支持多种身份认证策略，提供双因子、磐石盾、量子密钥等身份认证方式，保证用户登录安全。\r\n安全网关提供统一服务地址和接入端口，与服务发布平台网络隔离，支持用户灵活访问，有效兼顾生产效率和安全举措。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l1.png'),},
            {title:'应用商店',desc:'磐石高价值应用上云服务平台将所有高价值应用软件以服务方式发布，并通过“应用商店”对所有服务进行集中管理，用户可根据服务类别、使用频率等订阅相应应用服务。\n应用商店提供应用服务统计功能，可根据应用申请使用频率、时长等指标参数进行主动统计，为应用综合管理提供决策依据。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l7.png'),},
            {title:'资源申请',desc:'许可服务作为有限资源，系统支持统一管理并提供资源申请流程，用户可按需发起资源使用请求，审批通过后系统主动释放资源并对资源使用情况进行跟踪，主动追踪许可服务使用效率，闲置资源及时释放，确保资源服务价值最大化。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l8.png'),},
            {title:'资源管理',desc:'磐石高价值应用上云服务平台支持对基础计算及存储资源进行统一管理，管理员可通过统一访问界面实现对后台资源的统筹把握，全面监控资源占用及使用效率，有效跟踪服务运行与后台资源匹配效果，同时支持动态资源调整，并为最佳资源服务匹配比例提供科学依据。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l3.png'),},
            {title:'资源交付',desc:'磐石高价值应用上云服务平台交付管理组件承担服务请求处理、用户授权和应用发布等功能，通过友好可视化页面支持对资源、交付组、服务列表、发布策略等进行配置管理。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l2.png'),},
            {title:'运维监控',desc:'磐石高价值应用上云服务平台提供主动运维服务组件，系统会主动跟踪发布服务运行情况，并及时收集影响体验信息以及动态监控客户端运行状况，必要时支持主动干预并支持预警功能，极大丰富了运维手段和提升了运维效率。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l4.png'),},
          ]
        }
    },
    methods: {
        openRightDrawer() {
            this.$refs.son.showRightDrawer();
        },
    },
}
</script>

<style scoped>
  .clearfix{clear: both;}
  .cloudDesktop{background: #F6F6F6;padding-top: 17%;}
  .title{font-weight: normal;font-size: 4.65vw;border-left: 5px solid #E32121;padding-left: 10px;}
  .cloa{padding-top: 15px;padding-bottom: 40px;}
  .cloa p{font-size: 3.5vw;text-indent: 2em;color: #888;margin: 5px 0;}
  .cloa img{width: 90%;display: block;margin: 0 auto;margin-top: 10px;}
  .clob{padding: 5px;margin-top: 10px; background: #fff;margin-bottom: 20px;}
  .clob > p{font-size: 4vw;color: #101010;}
  .clob img{width: 96%;display: block;margin: 0 auto;}
  .cloba{width: 50%;float: left;}
  .cloba img{width: 30%;display: block;margin: 0 auto;}
  .cloba p:nth-child(2){font-size: 3.5vw;text-align: center;}
  .cloba p:nth-child(3){font-size: 3vw;color: #888;height: 190px;width: 94%;margin: 0 auto;}
  .cloc{margin-top: 20px;}
  .cloc > img{width: 100%;}
  .cloca{display: flex;}
  .clocb{width: 33.333%;}
  .clocba{width: 6px;height: 6px;border-radius: 3px;background: #888;float: left;margin-top: 6px;}
  .clocb p{font-size: 3vw;color: #888;list-style: disc;float: left;width: calc(100% - 15px);margin-left: 4px;}
</style>